









































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab1CalculationsFr',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      calculations: [] as File[],
      questions: [
        {
          en: 'Please upload your calculations for Parts A, B, and C, using the upload field below. You can upload a single file or multiple files.',
          fr: 'Veuillez télécharger vos calculs pour les parties A, B et C en utilisant le champ de téléchargement ci-dessous. Vous pouvez télécharger un seul fichier ou plusieurs fichiers.',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.calculations];
    },
  },
});
